



























































































































import { Component, Vue } from 'vue-property-decorator';
import { DashboardDataInterface } from '@/interfaces/DashboardDataInterface';
import DashboardRepository from '@/api/repositories/DashboardRepository';
import { RepositoryFactory } from '@/api/RepositoryFactory';
import { applicationStoreGetter } from '@/store/application.store';
import Company from '@/models/Company';
import { namespace } from 'vuex-class';
import { ROUTE_DRIVER } from '@/router/routes';
import moment from 'moment/moment';
import Ride from '@/models/Ride';
import RideRepository from '@/api/repositories/RideRepository';

const dashboardRepository: DashboardRepository = RepositoryFactory.get('dashboard');

const ApplicationStore = namespace('application');
const rideRepository: RideRepository = RepositoryFactory.get('ride');

@Component({
    components: {
        RideTableComponent: () => import(
            /* webpackChunkName: "RideTableComponent" */
            '@/components/RideTable.component.vue')
    }
})
export default class DashboardView extends Vue {
    @ApplicationStore.Getter(applicationStoreGetter.SELECTED_COMPANY)
    public selectedCompany!: Company;

    public dashboardData: DashboardDataInterface | null = null;
    private rides: Ride[] = [];
    public isLoading: boolean = false;

    private startMenu: boolean = false;
    private endMenu: boolean = false;
    private startDate: string | null = null;
    private endDate: string | null = null;

    public async mounted() {
        if (!this.selectedCompany?.id) return;
        try {
            this.isLoading = true;
            const apiResponse = await dashboardRepository.dashboardData(this.selectedCompany);
            this.dashboardData = apiResponse.data;

            const today = moment(new Date());
            this.endDate = today.format('yyyy-MM-DD');
            today.set({ date: 1 });
            this.startDate = today.format('yyyy-MM-DD');
            await this.searchRides();
        } finally {
            this.isLoading = false;
        }
    }

    private async searchRides() {
        const result = await rideRepository.companyRides({
            id: this.selectedCompany!.id!,
            from: this.startDate!,
            to: this.endDate!
        });
        this.rides = Ride.parseFromArray(result.data) as Ride[];
    }

    public openDrivers() {
        this.$router.push({ name: ROUTE_DRIVER });
    }
}
