var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-width fill-height pa-6 d-flex flex-column",attrs:{"id":"dashboard"}},[_c('div',{staticClass:"d-flex flex-column mb-6 view-standard-header"},[_c('span',{staticClass:"header-title primary--text mb-3 font-weight-bold"},[_vm._v(_vm._s(_vm.$t('DASHBOARD.TITLE')))])]),(_vm.dashboardData)?_c('div',{staticClass:"d-flex mb-9 flex-wrap"},[_c('v-card',{staticClass:"info-container elevation-0 mr-12 white d-flex justify-space-between pa-9"},[_c('div',{staticClass:"d-flex flex-column justify-center"},[_c('div',{staticClass:"info-title"},[_vm._v(_vm._s(_vm.dashboardData.dailyEarnings))]),_c('span',{staticClass:"infotext--text"},[_vm._v(_vm._s(_vm.$t('DASHBOARD.TODAYS_INCOME')))])]),_c('div',[_c('div',{staticClass:"icon-container machineBackground d-flex justify-center align-center"},[_c('v-icon',{attrs:{"x-large":"","color":"machineIcon"}},[_vm._v("mdi-cog")])],1)])]),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return _c('v-card',{staticClass:"info-container white mr-12 d-flex justify-space-between pa-9 pointer",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 5 : 0},on:{"click":_vm.openDrivers}},[_c('div',{staticClass:"d-flex flex-column justify-center"},[_c('div',{staticClass:"info-title"},[_vm._v(_vm._s(_vm.dashboardData.drivers))]),_c('span',[_vm._v(_vm._s(_vm.$t('DASHBOARD.DRIVERS')))])]),_c('div',[_c('div',{staticClass:"icon-container userBackground d-flex justify-center align-center pointer"},[_c('v-icon',{attrs:{"x-large":"","color":"userIcon"}},[_vm._v("fas fa-users")])],1)])])}}],null,false,4116193953)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return _c('v-card',{staticClass:"info-container white d-flex justify-space-between pa-9 pointer",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 5 : 0},on:{"click":_vm.openDrivers}},[_c('div',{staticClass:"d-flex flex-column justify-center"},[_c('div',{staticClass:"info-title"},[_vm._v(_vm._s(_vm.dashboardData.unverifiedDrivers))]),_c('span',[_vm._v(_vm._s(_vm.$t('DASHBOARD.UNVERIFIED_DRIVERS')))])]),_c('div',[_c('div',{staticClass:"icon-container userBackground d-flex justify-center align-center pointer"},[_c('v-icon',{attrs:{"x-large":"","color":"userIcon"}},[_vm._v("fas fa-times-circle")])],1)])])}}],null,false,48731220)})],1):_vm._e(),(_vm.dashboardData)?_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"mb-6 view-standard-header"},[_c('span',{staticClass:"header-title primary--text mb-3 font-weight-bold"},[_vm._v(_vm._s(_vm.$t('DASHBOARD.LAST_RIDES')))])]),_c('div',{staticClass:"d-flex mb-4 align-center"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"date-picker-container mr-3"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"date-picker-text-field",attrs:{"label":"Start date","readonly":"","outlined":"","hide-details":"","height":40},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},'v-text-field',attrs,false),on))]}}],null,false,2301290631),model:{value:(_vm.startMenu),callback:function ($$v) {_vm.startMenu=$$v},expression:"startMenu"}},[_c('v-date-picker',{attrs:{"max":_vm.endDate},on:{"input":function($event){_vm.startMenu = false}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1),_c('div',{staticClass:"date-picker-container"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"date-picker-text-field",attrs:{"label":"End date","readonly":"","outlined":"","hide-details":"","height":40},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},'v-text-field',attrs,false),on))]}}],null,false,721575879),model:{value:(_vm.endMenu),callback:function ($$v) {_vm.endMenu=$$v},expression:"endMenu"}},[_c('v-date-picker',{attrs:{"min":_vm.startDate,"max":new Date().toISOString()},on:{"input":function($event){_vm.endMenu = false}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1),_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{attrs:{"color":"primary ml-3"},on:{"click":_vm.searchRides}},[_vm._v(_vm._s(_vm.$t('RIDE.SEARCH')))])],1)])]),_c('ride-table-component',{attrs:{"rides":_vm.rides}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }